import React, { useState } from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
//import SupportChat from "../components/Organisms/SupportChat/SupportChat"
import PhoneForm from "../components/Organisms/PhoneForm/PhoneForm"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronRight, faEnvelope, faLock, faChevronDown,
    faChevronUp,
    faTimes,
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons"

export default function Support({ data }) {
    debugger;
    const page = data.allContentfulZSupport.edges[0].node
    const chatBox = data.allContentfulChatBox.edges[0].node

    //const Bold = ({ children }) => <span className="bold">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="heading4">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="d-inline-block">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.children[0].children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
           // [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const [toggleState, setToggleState] = useState(1)

    const toggleTab = index => {
        setToggleState(index)
    }

    const {
        id,
        metaData,
        spHBtn,
        spHHd,
        spHHd2,
        spHHd3,
        spHImage,
        spHRtxt,
        spS1Btn,
        spS1Btn2,
        spS1Btn3,
        spS1Hd,
        spS1Txt,
    } = page

    const {
        cbPh,
        cbNotif,
        cbHd,
        cbConcent,
        cbBtn,
        cbTitle,
        cbTitle2,
        cbTitle3,
        cbTitle4,
        cbTitle5,
        cbTitle6,
        cbTitle7,
    } = chatBox


    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/support" />
        <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/support" />
            </head>

            <div className="chatbox">
                {toggleState === 1 ? (
                    <div className="topbar">
                        <div className="row align-items-center">
                            <div className="col-lg-9 col-md-9 col-xl-9 col-sm-9 col-9">
                                <h3 className="h3-chat">
                                    {documentToReactComponents(cbHd.json, options)}
                                </h3>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(2)}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </Button>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(3)}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1 col-md-1 col-xl-1 col-sm-1 col-1"></div>
                        </div>
                    </div>
                ) : toggleState === 2 ? (
                    <div className="topbar">
                        <div className="row align-items-center">
                            <div className="col-lg-9 col-md-9 col-xl-9 col-sm-9 col-9 ">
                                <h3 className="h3-chat">
                                    {documentToReactComponents(cbHd.json, options)}
                                </h3>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(1)}
                                >
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </Button>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(3)}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1 col-md-1 col-xl-1 col-sm-1 col-1"></div>
                        </div>
                    </div>
                ) : toggleState === 4 ? (
                    <div className="topbar">
                        <div className="row align-items-center">
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(1)}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Button>
                            </div>
                            <div className="col-lg-8 col-md-8 col-xl-8 col-sm-8 col-8">
                                <h3 className="h3-chat">
                                    {documentToReactComponents(cbTitle3.json, options)}
                                </h3>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(2)}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </Button>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(3)}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1"></div>
                        </div>
                    </div>
                ) : toggleState === 5 ? (
                    <div className="topbar">
                        <div className="row align-items-center">
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(1)}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Button>
                            </div>
                            <div className="col-lg-8 col-md-8 col-xl-8 col-sm-8 col-8">
                                <h3 className="h3-chat">
                                    {documentToReactComponents(cbTitle2.json, options)}
                                </h3>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(2)}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </Button>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1">
                                <Button
                                    className="btns"
                                    buttonStyle="btn--primaryWhite"
                                    buttonSize="btn--txt3"
                                    onClick={() => toggleTab(3)}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </div>
                            <div className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-1"></div>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
                <div>
                    {toggleState === 1 ? (
                        <div className="bottombar">
                            <div className="row">
                                <h3 className="h3-chat3 pt-3 h1-darkBlue">{cbTitle}</h3>
                                <select required>
                                    <option value="" selected hidden>
                                        Other
                                    </option>
                                    <option value="API Integration">API Integration</option>
                                    <option value="Atlas">Atlas</option>
                                    <option value="Connect">Connect</option>
                                    <option value="Declines">Declines</option>
                                    <option value="Disputes">Disputes</option>
                                    <option value="Financial Reports">Financial Reports</option>
                                    <option value="Login Issues">Login Issues</option>
                                    <option value="Payment APIs">Payment APIs</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="row">
                                <a onClick={() => toggleTab(5)}>
                                    <h3 className="h3-chat2 pt-3 h1-darkBlue">
                                        {documentToReactComponents(cbTitle2.json, options)}
                                    </h3>
                                </a>
                            </div>
                            <div className="row">
                                <a onClick={() => toggleTab(4)}>
                                    <h3 className="h3-chat2 pt-3 h1-darkBlue">
                                        {documentToReactComponents(cbTitle3.json, options)}
                                    </h3>
                                </a>
                            </div>
                            <div className="row">
                                <a href={config.navigationURL_emailSupport}>
                                    <h3 className="h3-chat2 pt-3 h1-darkBlue">
                                        {documentToReactComponents(cbTitle4.json, options)}
                                    </h3>
                                </a>
                            </div>
                        </div>
                    ) : toggleState === 4 ? (
                        <PhoneForm {...chatBox} />
                    ) : toggleState === 5 ? (
                        <div>
                            <div className="bottombar">
                                <div className="row">
                                    <h3 className="h3-chat3 pt-3 h1-darkBlue">
                                        Sorry, we are down for maintenance. We will be back up
                                        shortly.
                                    </h3>
                                    <Button
                                        name="submit"
                                        type="submit"
                                        className="btns"
                                        buttonStyle="btn--primaryPurple"
                                        buttonSize="btn--txt3"
                                        href={config.navigationURL_emailSupport}
                                    >
                                        Please drop us an email for support services
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>

            <section className="bg-white">
                <div className="head-container2">
                    <div className="head-canvas">
                        <div className="row align-items-center">
                            <div className="col-lg-6 pb-5">
                                <div className="row">
                                    <h1 className="align-top h1-small h1-purple2">
                                        {spHHd2}
                                    </h1>
                                </div>
                                <div className="row pt-4">
                                    <h1 className="h1-largeMedium2 pt-3 h1-darkBlue">
                                        {spHHd}
                                    </h1>
                                </div>
                                <div className="row pt-4">
                                    <h1 className="h1-largeLight2 h1-darkBlue">
                                        {spHHd3}
                                    </h1>
                                </div>
                                <div className="row pt-4">
                                    <h2 className="h2-large pt-1 text-darkgray">
                                        {documentToReactComponents(spHRtxt.json, options)}
                                    </h2>
                                </div>
                            </div>
                            <div className="col-lg-6 pb-5 pl-5 bg-white">
                                <div className="text-center">
                                    <img src={spHImage.file.url} className="img-fluid" alt={spHImage.title} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-lightgray">
                <div className="sectionFit-container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 pb-5">
                            <div className="row">
                                <h3 className="h3-support pt-3 h1-darkBlue">
                                    {spS1Hd}
                                </h3>
                            </div>
                            <div className="row pt-3">
                                <p className="p-support pt-1 text-darkgray">
                                    {spS1Txt}
                                </p>
                            </div>
                            <div className="row pt-3">
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlinePurple2'
                                        buttonSize='btn--large3'
                                        href={config.navigationURL_register}
                                    >
                                        {spS1Btn}{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple2'
                                        buttonSize='btn--txt'
                                        href={config.navigationURL_register}
                                    >
                                        <FontAwesomeIcon className="" size="1x" icon={faEnvelope} className="faEnvelope" />

                                        {spS1Btn2}
                                    </Button>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple2'
                                        buttonSize='btn--txt'
                                        href={config.navigationURL_register}
                                    >
                                        <FontAwesomeIcon className="" size="1x" icon={faLock} className="faLock" />
                                        {spS1Btn3}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="colBox7">
                                <div className="row text-center pt-2">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Contact Support
                                    </Button>
                                </div>
                                <div className="row">
                                    <p className="p-support pt-1 text-darkgray">
                                        24 x 7 help from our support staff
                                    </p>
                                </div>
                                <div className="row pt-5">
                                    <p className="p-supportBold pt-1 h1-darkBlue">
                                        Popular Topics
                                    </p>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Account
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Billing
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Connect
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Disputes
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Get Started
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Payments
                                    </Button>
                                </div>
                                <div className="row">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple4'
                                        buttonSize='btn--txt'
                                        href=""
                                    >
                                        Payouts
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </Layout >
    );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZSupport(
        filter: {
        node_locale: { eq: $locale }
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                spHBtn
                spHHd
                spHHd2
                spHHd3
                spHImage {
                    description
                    file {
                        fileName
                        url
                    }
                    title
                }
                spHRtxt {
                    json
                }
                spS1Btn
                spS1Btn2
                spS1Btn3
                spS1Hd
                spS1Txt
            }
        }
    }
    allContentfulChatBox(filter: { node_locale: { eq: $locale } }) {
        edges {
          node {
            cbPh
            cbNotif
            cbHd {
              json
            }
            cbConcent
            cbBtn
            cbTitle
            cbTitle2 {
              json
            }
            cbTitle3 {
              json
            }
            cbTitle4 {
              json
            }
            cbTitle5
            cbTitle6
            cbTitle7
          }
        }
      }
}
`
