import React from "react"
import { navigateTo } from "gatsby-link"
import phoneStyle from "./PhoneForm.module.scss"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import Button from "../../Atoms/Button/Button"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}


export default class phoneForm extends React.Component {
  constructor(props) {
    super(props)
    debugger
    this.props = props
    this.state = {}
  }

  options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="heading4">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="copy">{children}</p>
      ),
    },
    renderMark: {},
    renderText: text =>
      text
        .replace(/\u2028/g, "")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br />, text]),
  }

  debugger

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    Promise.all([
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
      fetch("https://getform.io/f/f8237525-74ff-4231-adc6-7a47da409cd5", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
    ])
      .then(() => navigateTo("/thankyou"))
      .catch(error => alert(error))
  }

  render() {
    return (
      <div className="contactForm">
        <form
          name="callSupport"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="callSupport" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
          <div className="block">
            <label for="name">{this.props.cbTitle5}</label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={this.handleChange}
              pattern=".{,50}"
              required
              title="Not more than 50 symbols"
            />
          </div>
          <div className="block">
            <label for="workPhone">{this.props.cbTitle6}</label>
            <input
              type="text"
              id="workPhone"
              name="workPhone"
              inputMode="numeric"
              onChange={this.handleChange}
              pattern=".{,20}"
              required
              placeholder="+49 (555) 5555-5555"
              title="Not more than 20 symbols"
            />
          </div>
          <div className="block">
            <label for="query">{this.props.cbTitle7}</label>
            <input
              type="text"
              id="query"
              name="query"
              onChange={this.handleChange}
              requiredpattern=".{,200}"
              required
              title="Not more than 200 symbols"
            />
          </div>
          <div className="row justify-content-end pr-3 text-right pt-3">
            <Button
              name="submit"
              type="submit"
              className="btns"
              buttonStyle="btn--outlinePurpleBlue"
              buttonSize="btn--medium"
            >
              {this.props.cbBtn}
            </Button>
          </div>
        </form>
        <div className="row pl-4 pt-2 pr-3 justify-content-end text-right">
            <p className="p-chat text-darkgray3">
              {this.props.cbConcent}
            </p>
          </div>
      </div>
    )
  }
}
